<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"sunburst",
      },
      data:{
        type:Array,
        default:function() {
          return [
            {
              name: "Grandpa",
              children: [
                {
                  name: "Uncle Leo",
                  value: 15,
                  children: [
                    {
                      name: "Cousin Jack",
                      value: 2,
                    },
                    {
                      name: "Cousin Mary",
                      value: 5,
                      children: [
                        {
                          name: "Jackson",
                          value: 2,
                        },
                      ],
                    },
                    {
                      name: "Cousin Ben",
                      value: 4,
                    },
                  ],
                },
                {
                  name: "Father",
                  value: 10,
                  children: [
                    {
                      name: "Me",
                      value: 5,
                    },
                    {
                      name: "Brother Peter",
                      value: 1,
                    },
                  ],
                },
              ],
            },
            {
              name: "Nancy",
              children: [
                {
                  name: "Uncle Nike",
                  children: [
                    {
                      name: "Cousin Betty",
                      value: 1,
                    },
                    {
                      name: "Cousin Jenny",
                      value: 2,
                    },
                  ],
                },
              ],
            }];
        },
      },
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"旭日图",
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        var chart = echarts.init(document.getElementById(this.id),tdTheme);
        const option = {
          title: {
            text: this.name,
          },
          tooltip: {},
          series: {
            name:"",
            type: "sunburst",
            emphasis: {
              focus: "ancestor",
            },
            data: this.data,
            radius: [0, "90%"],
            label: {

            },
          },
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>