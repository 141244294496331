<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"axisBar",
      },
      data:{
        type:Array,
        default:function() {
          return [
            { name:"Rainfall",
              data: [
                2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
              ],
            },
            {
              name:"Evaporation",
              data: [
                2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
              ],

            },
          ];
        },
      },
      legendData: {
        type:Array,
        default:function() {
          return ["Rainfall", "Evaporation"];
        },

      },
      xAxisData:{
        type:Array,
        default:function() {
          return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        },
      },
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"堆叠折线图",
      },
      option:Object,
    },
    computed:{
      formatData:function() {
        return this.data.map(item=>{
          item.type= "bar";

          return item;
        });
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        const chart = echarts.init(document.getElementById(this.id),tdTheme);
        const option = {
          title: {
            text: this.name,
          },
          tooltip: {
            trigger: "axis",

          },
          legend: {
            data: this.option.legendData,
            right:"15%",
          },
          toolbox: {
            show: true,
            feature: {
              magicType: { show: true, type: ["line", "bar"] },
              restore: { show: true },
            },
            right:"5%",
          },
          calculable: true,
          xAxis: [
            {
              type: "category",
              // prettier-ignore
              data: this.option.xAxisData,
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series:this.formatData,
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>