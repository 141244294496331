<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "./theme.json"; // 引入默认主题

  export default {
    props:{
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      value:Object,
      id:{
        type:String,
        default:"radarCharts",
      },
      name:{
        type:String,
        default:"雷达图",
      },
      option:{
        type:Array,
        default:function() {
          return {indicator:[
            { name: "Sales", max: 6500 },
            { name: "Administration", max: 16000 },
            { name: "Information Technology", max: 30000 },
            { name: "Customer Support", max: 38000 },
            { name: "Development", max: 52000 },
            { name: "Marketing", max: 25000 },
          ]};
        },
      },
      data:{
        type:Object,
        default:function() {
          return  [
            {
              value: [4200, 3000, 20000, 35000, 50000, 18000],
              name: "Allocated Budget",
            },
            {
              value: [5000, 14000, 28000, 26000, 42000, 21000],
              name: "Actual Spending",
            },
          ];
        },
      },
    },
    data() {
      return {
      };
    },
    computed:{
      legendData:function() {
        const arr = [];
        if (this.data instanceof Array) {
          this.data.forEach(item=>{
            arr.push(item.name);
          });
        }
        return arr;
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        // immediate: true,
        deep: true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        var chart = echarts.init(document.getElementById(this.id),tdTheme);

        const option = {
          title: {
            text: this.name,
          },
          colors: [
            "#37a2da",
            "#32c5e9",
            "#67e0e3",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
          ],
          legend: {
            data: this.legendData,
            bottom:0,

          },
          tooltip: {
            trigger: "item",
          },
          radar: {
            // shape: 'circle',
            indicator:this.option.indicator,
          },
          series: [
            {
              type: "radar",
              data:this.data,
              center: ["50%", "40%"],

            },
          ],
        };
        chart.setOption(option, true);
      },
    },
  };
</script>

<style scoped>

</style>