<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"scatter",
      },
      data:{
        type:Array,
        default:function() {
          return [
            { name:"2020",
              data: [[28604, 77, 17096869, "Australia", 1990], [31163, 77.4, 27662440, "Canada", 1990], [1516, 68, 1154605773, "China", 1990], [13670, 74.7, 10582082, "Cuba", 1990], [28599, 75, 4986705, "Finland", 1990], [29476, 77.1, 56943299, "France", 1990], [31476, 75.4, 78958237, "Germany", 1990], [28666, 78.1, 254830, "Iceland", 1990], [1777, 57.7, 870601776, "India", 1990], [29550, 79.1, 122249285, "Japan", 1990], [2076, 67.9, 20194354, "North Korea", 1990], [12087, 72, 42972254, "South Korea", 1990], [24021, 75.4, 3397534, "New Zealand", 1990], [43296, 76.8, 4240375, "Norway", 1990], [10088, 70.8, 38195258, "Poland", 1990], [19349, 69.6, 147568552, "Russia", 1990], [10670, 67.3, 53994605, "Turkey", 1990], [26424, 75.7, 57110117, "United Kingdom", 1990], [37062, 75.4, 252847810, "United States", 1990]],
            },
          ];
        },
      },
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"气泡图",
      },
      option:Object,
    },
    data() {
      return {chart:null};
    },
    computed:{
      formatData:function() {
        const arr=[];

        arr.push({
          name: "",
          type: "scatter",
          symbolSize: function(val) {
            if (500>val[2]&&val[2]>100) {
              return val[2] /3;
            }else if (val[2]>50&&val[2]<100) {
              return  val[2] /2;
            }
            return val[2]  ;
          },
          data: this.data.map(itemList=>{
            return [itemList[1], itemList[0], itemList[2]];
          }),
          animationDelay: function(idx) {
            return idx * 5;
          },
        });

        return arr;
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        const chart = echarts.init(document.getElementById(this.id),tdTheme);
        this.chart = chart;
        let arr = [];
        for (let i = 0; i < 20; i++) {
          arr.push(i+"年");
        }
        let option = {
          title: {
            text: this.name,
          },
          legend: {
            data: ["Punch Card"],
            left: "right",
          },
          tooltip: {
            position: "top",
            formatter: (params)=>{
              return (
                this.option.yaxis[params.value[1]]+":"+params.value[2]+"台"
              );
            },
          },
          grid: {
            left: 2,
            bottom: 10,
            // right: 10,
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: arr,
            boundaryGap: true,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              interval: 2,
            },
          },
          yAxis: {
            type: "category",
            data: this.option.yaxis,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
            },
          },
          series:this.formatData,
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>