<template>
  <div>
    <axis-bar
      v-if="type.toLocaleLowerCase()==='axisBar'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :name="name"
      :option="option"
      :data="data"></axis-bar>
    <default-line
      v-if="type.toLocaleLowerCase()==='defaultLine'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :name="name"
      :option="option"
      :data="data"></default-line>
    <radar-chart
      v-if="type.toLocaleLowerCase()==='RadarChart'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :option="option"
      :name="name"
      :data="data"></radar-chart>
    <stack-charts
      v-if="type.toLocaleLowerCase()==='stackCharts'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :name="name"
      :option="option"
      :data="data"></stack-charts>
    <sankey
      v-if="type.toLocaleLowerCase()==='sankey'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :name="name"
      :option="option"
      :data="data"></sankey>
    <scatter
      v-if="type.toLocaleLowerCase()==='scatter'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :option="option"
      :name="name"
      :data="data"></scatter>
    <sunburst
      v-if="type.toLocaleLowerCase()==='Sunburst'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :name="name"
      :data="data"></sunburst>
    <wordcloud
      v-if="type.toLocaleLowerCase()==='Wordcloud'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :height="height"
      :name="name"
      :data="data"></wordcloud>
    <ybar
      v-if="type.toLocaleLowerCase()==='Ybar'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :name="name"
      :height="height"
      :data="data"></ybar>
    <pie
      v-if="type.toLocaleLowerCase()==='pie'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :name="name"
      :height="height"
      :data="data"></pie>
    <bar
      v-if="type.toLocaleLowerCase()==='bar'.toLocaleLowerCase()"
      :id="id"
      :width="width"
      :name="name"
      :height="height"
      :option="option"
      :data="data"
    ></bar>
  </div>
</template>

<script>
  import axisBar from "@/common/echart/axisBar";
  import defaultLine from "@/common/echart/defaultLine";
  import RadarChart from "@/common/echart/RadarChart";
  import sankey from "@/common/echart/sankey";
  import scatter from "@/common/echart/scatter";
  import stackCharts from "@/common/echart/stackCharts";
  import Sunburst from "@/common/echart/Sunburst";
  import Wordcloud from "@/common/echart/Wordcloud";
  import Ybar from "@/common/echart/Ybar";
  import pie from "@/common/echart/pie";
  import bar from "@/common/echart/bar";
  import format from "@/util/bisScreen";
  export default {
    components:{axisBar,defaultLine,RadarChart,stackCharts,sankey,scatter,Sunburst,Wordcloud,Ybar,pie,bar},
    props:{
      type:{
        type:String,
        default:"raderchart", 
      },
      id:{
        type:String,
        required:true,
      },
      height:String,
      width:String,
      params:{
        type:Object,
        default:function() {
          return {};
        },
      },
      url:{
        type:String,
      },
      name:String,
      valueName:{
        type:Object,
        default:function() {
          return ["inMaintenanceQuantity","freeMaintenanceQuantity"];
        },
      },
    },
    data() {
      return {
        data:[],
        option:{
          indicator:[],
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods:{
      getData() {
        let params = this.params;
        let http = this.post ? this.$http.post(this.url, params) : this.$http.get(this.url, params);
        http.then(data => {
          if (this.id==="sunburst") {
            this.data = format(data,this.valueName,"sunburst").arr;
          }else {
            this.data = format(data,this.valueName,this.type).arr;

          }
          // if (data.option) {
          this.option= format(data,this.valueName,this.type).option;
          // }
          // this.status = "finish";
        }).catch(() => {
          // this.status = "error";
        });
      },
    },
  };
</script>

<style scoped>

</style>