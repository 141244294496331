<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"stack",
      },
      data:{
        type:Array,
        default:function() {
          return [
            { name:"Email",
              data: [120, 132, 101, 134, 90, 230, 210],
            },
            {
              name:"Union Ads",
              data: [220, 182, 191, 234, 290, 330, 310],

            },
            {
              name: "Video Ads",
              data: [150, 232, 201, 154, 190, 330, 410],
            },
            {
              name: "Direct",
              data: [320, 332, 301, 334, 390, 330, 320],
            },
            {
              name: "Search Engine",
              data: [820, 932, 901, 934, 1290, 1330, 1320],
            },
          ];
        },
      },
      legendData: {
        type:Array,
        default:function() {
          return ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"]; 
        },

      },
      xAxisData:{
        type:Array,
        default:function() {
          return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        },
      },
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"堆叠折线图",
      },
    },
    computed:{
      formatData:function() {
        return this.data.map(item=>{
          item.type="line",
          item.smooth=true,
          item.stack= "Total",
          item.areaStyle={},
          item.emphasis={
            focus: "series",
          };
          return item;
        });
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        const chart = echarts.init(document.getElementById(this.id),tdTheme);
        const option = {
          title: {
            text: this.name,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            data: this.legendData,
            right:"2%",
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {},
          //   },
          // },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: this.xAxisData,
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series:this.formatData,
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>