<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"bar",
      },
      data:{
        type:Array,
        default:function() {
          return[
            { value: 1048, name: "Search Engine" },
            { value: 735, name: "Direct" },
            { value: 580, name: "Email" },
            { value: 484, name: "Union Ads" },
            { value: 300, name: "Video Ads" },
          ];
        },
      },
      option:Object,
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"柱状图",
      },
      radius:{
        type:Array,
        default:function() {
          return ["40%", "70%"];

        },
      },
    },
    computed:{
      formatData:function() {
        let arr = [];
        if (this.data.every(item=>{
          typeof item===Object;
        })) {
          this.data.forEach(item=>{
            arr.push({
              name: item.name,
              type: "bar",
              data: item.data.map(itemList=>{
                return itemList.value;
              }),
            });
          });
        }else {
          arr.push({type: "bar",data:this.data});
        }
        return arr;
      },
      legendData:function() {
        let arr=[];
        this.data.forEach(item=>{
          arr.push(item.name);
        });
        return arr;
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        const chart = echarts.init(document.getElementById(this.id),tdTheme);
        const option = {
          title: {
            text: this.name,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "3%",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {
            data: this.legendData,
            // left: "right",
            right:"5%",
          },
          xAxis: [
            {
              type: "category",
              data: this.option.xAxisData,
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: this.formatData,
        };

        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>