<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"line",
      },
      data:{
        type:Array,
        default:function() {
          return [
            { name:"2020",
              data:[18203, 23489, 29034, 104970, 131744, 630230],
            },
            {
              name:"2021",
              data: [19325, 23438, 31000, 121594, 134141, 681807],
            },
          ];
        },
      },
      xAxisData:{
        type:Array,
        default:function() {
          return  ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        },
      },
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"基础折线图",
      },
      option:Object,
    },
    computed:{
      formatData:function() {
        return this.data.map(item=>{
          item.type= "line";
          return item;
        });
      },
      legendData:function() {
        const arr= [];
        this.data.forEach(item=>{
          arr.push(item.name);
        });
        return arr;
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        const chart = echarts.init(document.getElementById(this.id),tdTheme);
        const option = {
          title: {
            text:this.name,
          },
          colors: [
            "#37a2da",
            "#32c5e9",
            "#67e0e3",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
          ],
          xAxis: {
            type: "category",
            data:this.option?.xAxisData?this.option.xAxisData:this.xAxisData,
          },
          yAxis: {
            type: "value",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: this.legendData,
            left:"center",
            bottom:0,
          },
          series: this.formatData,
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>