<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"sankey",
      },
      data:{
        type:Array,
        default:function() {
          return [
            {
              name: "a",
            },
            {
              name: "b",
            },
            {
              name: "a1",
            },
            {
              name: "a2",
            },
            {
              name: "b1",
            },
            {
              name: "c",
            },
          ];
        },
      },
      link:{
        type:Array,
        default:function() {
          return [
            {
              source: "a",
              target: "a1",
              value: 5,

            },
            {
              source: "a",
              target: "a2",
              value: 3,
            },
            {
              source: "b",
              target: "b1",
              value: 8,
            },
            {
              source: "a",
              target: "b1",
              value: 3,
            },
            {
              source: "b1",
              target: "a1",
              value: 1,
            },
            {
              source: "b1",
              target: "c",
              value: 2,
            },
          ];
        },
      },
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"桑基图",
      },
    },
    computed:{
      linkFormat:function() {
        return this.link.map(item=>{
          item.lineStyle={
            color:"rgb(" +
              Math.round(Math.random() * 255) +
              ", " + Math.round(Math.random() * 255) +
              ", " + Math.round(Math.random() * 255) + ")",
          };
          return item;
        });
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        var chart = echarts.init(document.getElementById(this.id),tdTheme);
        const option = {
          title: {
            text: this.name,
          },
          series: {
            type: "sankey",
            layout: "none",
            emphasis: {
              focus: "adjacency",
            },
            data: this.data,
            links: this.linkFormat,
          },
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>