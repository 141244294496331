<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    props:{
      id:{
        type:String,
        default:"axisBar",
      },
      data:{
        type:Array,
        default:function() {
          return[
            { value: 1048, name: "Search Engine" },
            { value: 735, name: "Direct" },
            { value: 580, name: "Email" },
            { value: 484, name: "Union Ads" },
            { value: 300, name: "Video Ads" },
          ];
        },
      },
      legendData: {
        type:Array,
        default:function() {
          return ["Rainfall", "Evaporation"];
        },

      },
      xAxisData:{
        type:Array,
        default:function() {
          return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        },
      },
      width:{
        type:String,
        default:"580px",
      },
      height:{
        type:String,
        default: "540px",
      },
      name:{
        type:String,
        default:"饼图",
      },
      radius:{
        type:Array,
        default:function() {
          return ["40%", "70%"];

        },
      },
    },
    watch:{
      data:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {
      this.drawPic();
    },
    methods:{
      drawPic() {
        const chart = echarts.init(document.getElementById(this.id),tdTheme);

        const option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            left: "center",
            bottom:0,

          },
          title: {
            text: this.name,
          },
          series: [
            {
              // name: this.name,
              type: "pie",
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "20",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: this.data,
            },
          ],
        };
        if(this.radius instanceof Array && this.radius.length>0) {
          option.series[0].radius = this.radius;
        }
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>